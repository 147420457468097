html, * {
  font-family: Roboto, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.section-title {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
}

/**
    Survey
 */
.Survey {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

/**
    Questions
 */
.Question {
  padding: 1rem;
  width: 100%;
}

@media screen and (min-width: 48em) {
  .Question.inline {
    width: 50%;
  }
}

p {
  margin-bottom: 1rem;
}

/**
    Question Title
 */
h2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
}

/**
    Slider
 */
.slider {
  display: flex;
  justify-content: center;
}

.slider-label {
  display: flex;
  flex: 1;
  margin-top: 1rem;
  justify-content: center;
  padding: 0 1rem;
}

.slider .range {
  display: flex;
  flex-direction: column;
  flex: 8;
}

.slider-value {
  text-align: center;
  font-weight: 500;
}

/**
    Grid
 */
.grid-response {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-cell {
  &:first-child {
    flex: 1;
  }

  &:not(:first-child) {
    flex: 40;
  }
}

table {
  width: 100%;
  overflow: hidden;

  tbody {
    th {
      width: 33%;
      vertical-align: top;
      text-align: left;
    }

    td {
      span {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 500;

        + input[type=text] {
          width: calc(100% - 1rem);
          float: right;
        }
      }

      vertical-align: middle;
    }
  }

  &.threed-grid {
    min-width: 768px;
  }
}

.threed-grid {
  td, th {
    padding-top: 0.5rem;
  }

  td {
    text-align: center;
  }
}

/*
    Scrollbars
 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;

  &:active {
    background: rgba(0, 0, 0, 0.61);
    -webkit-border-radius: 100px;
  }
}

/*
    Generic Row/Col
*/
.row {
  display: flex;
}

.col {
  flex: 1;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}